import * as React from "react";
import { graphql } from "gatsby";
import VideoJS from "../../components/videojs";

const VOD = ({ data }) => {

  const playerRef = React.useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: data.mdx.frontmatter.m3u8_url,
        type: "application/x-mpegURL",
      },
    ],
    poster: data.mdx.frontmatter.thumbnail.childImageSharp.fixed.src,
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };

  return (
    
      <div className="h-screen v-screen bg-white">
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
      </div>

  );
};

export const query = graphql`
  query ($slug: String) {
    mdx(slug: { eq: $slug }) {
      body
      frontmatter {
        title
        authors
        team
        department
        length
        m3u8_url
        thumbnail {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 400)
            fixed(width: 960) {
              src
            }
          }
        }
        date
      }
    }
  }
`;

export default VOD;
